export function prettyLog(
  label,
  options = { background: '#195c8c', color: '#fff', logOnProduction: false },
  ...params
) {
  if (!options.logOnProduction && process.env.NODE_ENV === 'production') {
    return
  }

  const styles = [
    `color: ${options.color}`,
    `background: ${options.background}`,
    `border-radius: 0.5em`,
    `font-weight: bold`,
    `padding: 0.25em 0.5em`,
  ]
  console.log('%c' + label, styles.join('; '), ...params)
}
