<template>
  <div class="error">
    <h1 class="ff:secondary fs:24-rem fw:bold mb:24-rem fw:bold">
      Something went wrong
    </h1>
    <p v-if="message" class="mb:24-rem">{{ message }}</p>
    <k-callout theme="light" type="error" class="mb:24-rem">
      <p>Error code: {{ code }}</p>
    </k-callout>
    <k-card>
      <h2 class="mb:8-rem fw:bold">Try one of these</h2>
      <ul class="list-reset">
        <li class="mb:8-rem">
          <k-link to="https://kasa.com/" tag="a" force-style="inline-text"
            >Home</k-link
          >
        </li>
        <li>
          <k-link
            to="https://kasa.com/get-help"
            tag="a"
            force-style="inline-text"
            >Get help</k-link
          >
        </li>
      </ul>
    </k-card>
  </div>
</template>

<script>
export default {
  name: 'Error',
  props: {
    code: [String, Number],
    message: String,
  },
}
</script>

<style lang="scss" scoped></style>
