export function createMetaInfo({
  title,
  description,
  robots = 'noindex nofollow',
} = {}) {
  return {
    title,
    titleTemplate: '%s | Kasa',
    meta: [
      {
        name: 'description',
        content: description,
      },
      {
        name: 'robots',
        content: robots,
      },
    ],
  }
}
