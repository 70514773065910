import Vue from 'vue'
import VueRouter from 'vue-router'
import TypeformView from '@/views/Form'
import NotFoundView from '@/views/NotFound'
import PostStayFeedback from '@/views/PostStayFeedback'
import store from '@/store'
import * as guards from '@/router/guards'

Vue.use(VueRouter)

const routes = [
  {
    path: '/typeform/:formId',
    name: 'typeform',
    component: TypeformView,
  },
  {
    path: '/typeform/post-stay/:formId',
    name: 'typeform/post-stay',
    component: PostStayFeedback,
  },
  {
    path: '*',
    name: 'not-found',
    component: NotFoundView,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  guards.setUserId({ store, to })
  next()
})

router.afterEach((to, from) => {
  // Not really a virtualPageView, just query param change
  if (to.name === from.name && to.path === from.path) {
    return
  }
  Vue.prototype.$segment.page()
})

export default router
