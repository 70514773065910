import Vue from 'vue'
import VueCookies from 'vue-cookies'
import { defaultConfigs } from '@/constants'

Vue.use(VueCookies)

Vue.$cookies.config(
  defaultConfigs.EXPIRES,
  defaultConfigs.PATH,
  defaultConfigs.DOMAIN
)
