import Vue from 'vue'

import Segment from '../plugins/segment'
import store from '@/store'
import { MEASURING_OPT_IN } from '@/constants'
import { _meSessionId, _meHitTimestamp } from '@/utils'
import { prettyLog } from '@/utils'

const {
  VUE_APP_SEGMENT_WRITE_KEY,
  VUE_APP_SEGMENT_SNIPPET_VERSION,
  VUE_APP_SEGMENT_DISABLED,
  VUE_APP_SEGMENT_MOCK,
  NODE_ENV,
} = process.env

Vue.use(Segment, {
  writeKey: VUE_APP_SEGMENT_WRITE_KEY,
  snippetVersion: VUE_APP_SEGMENT_SNIPPET_VERSION,
  /**
   * Make sure segment is only loaded after cookies have been initiated
   * */
  disabled:
    VUE_APP_SEGMENT_DISABLED === 'true' &&
    !(
      Vue.$cookies.get(MEASURING_OPT_IN) === 'true' ||
      Vue.$cookies.get(MEASURING_OPT_IN) === null
    ),
  debug: NODE_ENV !== 'production',
  mock: VUE_APP_SEGMENT_MOCK === 'true',
  sourceMiddlewares: [
    isMeasuringEnabledMiddleware,
    checkUserAgentMiddleware,
    transformPayloadMiddleware,
  ],
})

function isMeasuringEnabledMiddleware({ payload, next }) {
  if (
    Vue.$cookies.get(MEASURING_OPT_IN) === 'true' ||
    Vue.$cookies.get(MEASURING_OPT_IN) === null
  ) {
    next(payload)
  } else {
    prettyLog(
      '📈 Middleware: Segment disabled',
      { background: '#52bd95' },
      payload
    )
  }
}

function checkUserAgentMiddleware({ payload, next }) {
  if (['triptease-bot'].includes(window.navigator.userAgent)) {
    prettyLog(
      '📈 Middleware: Segment disabled for userAgent',
      { background: '#52bd95' },
      window.navigator.userAgent
    )
    return
  }
  next(payload)
}

function transformPayloadMiddleware({ payload, next }) {
  payload.obj.properties = {
    ...payload.obj.properties,
    Id: store.state.userId,
    me_sessionId: _meSessionId(),
    me_hitTimestamp: _meHitTimestamp(),
  }
  next(payload)
}
