import Vue from 'vue'
import Vuex from 'vuex'

import { SET_USER_ID } from '@/constants/store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId: null,
  },
  getters: {},
  mutations: {
    [SET_USER_ID](state, value) {
      state.userId = value
    },
  },
  actions: {},
  modules: {},
})
