<template>
  <main v-if="redirectingToGoogle" class="stretch-height">
    <RedirectToGoogleLoading :name="params.name" class="page-content" />
  </main>
  <Typeform
    v-else
    :form-id="formId"
    :hidden-fields="$route.query"
    class="stretch-height"
    @ready="onReady"
    @submitted="onSubmit"
  />
</template>

<script>
import Typeform from '@/components/Typeform'
import RedirectToGoogleLoading from '@/components/RedirectToGoogleLoading'
import { transformFeedbackQueryParams } from '@/utils'

import {
  createMetaInfo,
  getObjectFromLocalStorage,
  saveObjectToLocalStorage,
  wait,
} from '@/utils'

export const feedbackFormTypes = {
  GOOGLE: 'google',
  TYPEFORM: 'typeform',
}

export default {
  name: 'PostStayFeedbackView',
  components: {
    Typeform,
    RedirectToGoogleLoading,
  },
  beforeRouteEnter(to, from, next) {
    const params = transformFeedbackQueryParams(to.query)
    if (!areParamsValid(params)) {
      next({
        name: 'not-found',
        params: [to.path],
        query: to.query,
        replace: true,
      })
      return
    }

    next((vm) => {
      const lsKey = `post-review-rating-${params.confirmationCode}`
      const firstRating = getObjectFromLocalStorage(lsKey)
      const feedbackFormType = getFeedbackFormType(
        firstRating?.rating,
        params.rating
      )

      saveObjectToLocalStorage(lsKey, params)

      if (feedbackFormType === feedbackFormTypes.GOOGLE) {
        vm.redirectingToGoogle = true
      }
    })
  },
  metaInfo() {
    return createMetaInfo({
      title: 'Review your stay at Kasa',
    })
  },
  data() {
    return {
      redirectingToGoogle: false,
    }
  },
  computed: {
    formId() {
      return this.$route.params.formId
    },
    params() {
      return transformFeedbackQueryParams(this.$route.query)
    },
  },
  async mounted() {
    if (this.redirectingToGoogle) {
      await wait(3000)
      const url = `https://search.google.com/local/writereview?placeid=${this.params.googleBusinessId}`
      window.location.replace(url)
    }
  },
  methods: {
    onReady() {
      this.$segment.track('Typeform Opened', {
        formId: this.formId,
        confirmationCode: this.params.confirmationCode,
        answers: this.params.rating,
      })
    },
    onSubmit(e) {
      this.$segment.track('Typeform Submitted', {
        formId: this.formId,
        confirmationCode: this.params.confirmationCode,
        formSubmissionId: e.responseId,
        answers: this.params.rating,
      })
    },
  },
}

export function areParamsValid(params = {}) {
  if (!params.confirmationCode) {
    return false
  }

  if (![1, 2, 3, 4, 5].includes(params.rating)) {
    return false
  }

  if (!params.googleBusinessId) {
    return false
  }

  return true
}

export function getFeedbackFormType(firstRating, currentRating) {
  let type

  if (!firstRating) {
    if (currentRating === 5) {
      type = feedbackFormTypes.GOOGLE
    } else {
      type = feedbackFormTypes.TYPEFORM
    }
  } else if (firstRating === 5) {
    type = feedbackFormTypes.GOOGLE
  } else if (currentRating === 5) {
    type = feedbackFormTypes.GOOGLE
  } else {
    type = feedbackFormTypes.TYPEFORM
  }

  return type
}
</script>

<style lang="scss" scoped></style>
