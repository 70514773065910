<template>
  <main>
    <Error
      :code="errors.NOT_FOUND.code"
      :message="errors.NOT_FOUND.message"
      class="error-page"
    />
  </main>
</template>

<script>
import Error from '@/components/Error'
import { errors } from '@/constants'
import { createMetaInfo } from '@/utils'

export default {
  name: 'NotFoundView',
  components: {
    Error,
  },
  metaInfo() {
    return createMetaInfo({
      title: errors.NOT_FOUND.message,
    })
  },
  data() {
    return {
      errors,
    }
  },
}
</script>

<style lang="scss" scoped></style>
