<template>
  <div ref="typeformContainer" class="stretch-height"></div>
</template>

<script>
import { createWidget } from '@typeform/embed'
import '@typeform/embed/build/css/widget.css'

export default {
  name: 'Typeform',
  props: {
    formId: {
      type: String,
      required: true,
    },
    hiddenFields: Object,
  },
  mounted() {
    createWidget(this.formId, {
      container: this.$refs.typeformContainer,
      onReady: () => {
        this.$emit('ready')
      },
      onSubmit: (e) => {
        this.$emit('submitted', e)
      },
      hidden: this.hiddenFields,
    })
  },
}
</script>

<style lang="scss" scoped></style>
