import { SET_USER_ID } from '@/constants'
import { prettyLog } from '@/utils'

export function setUserId({ store, to } = {}) {
  const userId = to.query.userid || to.query.userId
  if (userId) {
    store.commit(SET_USER_ID, userId)
    prettyLog(`🧍 User ID`, { background: '#dcfa64', color: '#010101' }, userId)
  }
}
