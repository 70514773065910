export function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function saveObjectToLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}

export function getObjectFromLocalStorage(key) {
  try {
    const obj = JSON.parse(localStorage.getItem(key))
    return isObject(obj) ? obj : null
  } catch (e) {
    return null
  }
}

export function isObject(value) {
  const type = typeof value
  return value !== null && (type === 'object' || type === 'function')
}
