<template>
  <Typeform
    v-if="formId"
    :form-id="formId"
    :hidden-fields="$route.query"
    @ready="onReady"
    @submit="onSubmit"
  />
</template>

<script>
import Typeform from '@/components/Typeform'
import { createMetaInfo, transformFeedbackQueryParams } from '@/utils'

/** Test form: https://kasaliving.typeform.com/to/nT65JwD3 */

export default {
  name: 'FormView',
  components: {
    Typeform,
  },
  metaInfo() {
    return createMetaInfo({
      title: `Fill form`,
    })
  },
  computed: {
    formId() {
      return this.$route.params.formId
    },
    params() {
      return transformFeedbackQueryParams(this.$route.query)
    },
  },
  methods: {
    onReady() {
      this.$segment.track('Typeform Opened', {
        formId: this.formId,
        confirmationCode: this.params.confirmationCode,
        answers: this.params.rating,
      })
    },
    onSubmit(e) {
      this.$segment.track('Typeform Submitted', {
        formId: this.formId,
        confirmationCode: this.params.confirmationCode,
        formSubmissionId: e.responseId,
        answers: this.params.rating,
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
