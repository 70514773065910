<template>
  <div id="app" class="stretch-height">
    <CookiePopup
      v-if="isCookiePopupVisible"
      @accept-all="acceptCookiesAndHidePopup"
    />
    <router-view />
  </div>
</template>

<script>
import CookiePopup from '@/components/CookiePopup'
import { MEASURING_OPT_IN, PERSONALIZATION_OPT_IN } from '@/constants'

export default {
  components: {
    CookiePopup,
  },
  data() {
    return {
      isCookiePopupManuallyHidden: false,
    }
  },
  computed: {
    isCookiePopupVisible() {
      return (
        this.$cookies.get(MEASURING_OPT_IN) === null &&
        this.$cookies.get(PERSONALIZATION_OPT_IN) === null &&
        !this.isCookiePopupManuallyHidden
      )
    },
  },
  mounted() {
    if (this.isCookiePopupVisible) {
      this.acceptCookies()
    }
  },
  methods: {
    acceptCookiesAndHidePopup() {
      this.acceptCookies()
      this.isCookiePopupManuallyHidden = true
    },
    acceptCookies() {
      this.$cookies.set(MEASURING_OPT_IN, true)
      this.$cookies.set(PERSONALIZATION_OPT_IN, true)
    },
  },
}
</script>

<style lang="scss" scoped></style>
