const prettyLog = require('../utils/pretty-log').prettyLog

module.exports = {
  /**
   * @typedef {Object} Options - Segment plugin options
   * @property {string} writeKey - Segment write key
   * @property {string} snippetVersion - Segment snippet version
   * @property {boolean} disabled - Disable script loading, use for example if you need GDPR compliance
   * @property {Object} debug - Enable analytics debug
   * @property {Object} mock - Mock analytics
   * @property {Object} settings - Segment load options, see more details: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#load-options
   * @property {Function[]} sourceMiddlewares - Segment source middlewares, see more details: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/middleware/
   */

  /**
   * @param {import('vue').default} Vue
   * @param {Options} options - Segment plugin options
   */
  install(Vue, options = {}) {
    if (hasMissingRequiredOption(options)) {
      return
    }

    const analytics = (window.analytics = window.analytics || [])

    if (analytics.initialize) {
      return
    }

    if (analytics.invoked) {
      if (window.console && console.error) {
        console.error('Segment snippet included twice.')
      }
      return
    }

    analytics.invoked = true

    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
      'addSourceMiddleware',
      'addIntegrationMiddleware',
      'setAnonymousId',
      'addDestinationMiddleware',
    ]

    analytics.factory = function (method) {
      return function () {
        const args = Array.prototype.slice.call(arguments)
        args.unshift(method)
        analytics.push(args)
        return analytics
      }
    }

    for (let i = 0; i < analytics.methods.length; i++) {
      const key = analytics.methods[i]
      analytics[key] = analytics.factory(key)
    }

    analytics.SNIPPET_VERSION = options.snippetVersion

    analytics.load = function (key, options) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src =
        'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js'

      const first = document.getElementsByTagName('script')[0]
      first.parentNode.insertBefore(script, first)
      analytics._loadOptions = options
    }

    analytics.debug(options.debug)
    analytics.ready(() => log('Segment is ready'))

    if (options.sourceMiddlewares && options.sourceMiddlewares.length) {
      options.sourceMiddlewares.forEach((middleware) => {
        analytics.addSourceMiddleware(middleware)
      })
    }

    if (options.mock) {
      log('Using mock Segment', options)
      Object.defineProperty(Vue.prototype, '$segment', {
        get() {
          return mockSegment()
        },
      })
    } else if (options.disabled) {
      log('Segment is disabled', options)
      Object.defineProperty(Vue.prototype, '$segment', {
        get() {
          return window.analytics
        },
      })
    } else {
      log('Using real Segment', options)
      analytics.load(options.writeKey, options.settings)
      Object.defineProperty(Vue.prototype, '$segment', {
        get() {
          return window.analytics
        },
      })
    }
  },
}

/**
 * @param {Options} options
 * @returns {boolean}
 */
function hasMissingRequiredOption(options) {
  if (options.disabled || options.mock) {
    return false
  }

  const REQUIRED_OPTIONS = ['writeKey', 'snippetVersion']
  let hasMissingOption = false

  REQUIRED_OPTIONS.forEach((option) => {
    if (!options[option] || !options[option].length) {
      console.warn(`Please provide [${option}] for Segment`)
      hasMissingOption = true
    }
  })

  return hasMissingOption
}

function mockSegment() {
  /**
   * TODO:
   * This mock Segment ignores additional data set in addSourceMiddleware
   * and default params, like: title, url, path, referrer
   * */
  const fn = (eventName, ...params) => {
    log(eventName, ...params)
  }

  return {
    identify: (...params) => fn('Event: identify', ...params),
    track: (...params) => fn('Event: track', ...params),
    trackLink: (...params) => fn('Event: trackLink', ...params),
    trackForm: (...params) => fn('Event: trackForm', ...params),
    page: (...params) => fn('Event: page', ...params),
    group: (...params) => fn('Event: group', ...params),
    alias: (...params) => fn('Event: alias', ...params),
    ready: (...params) => fn('Event: ready', ...params),
    debug: (...params) => fn('Event: debug', ...params),
    on: (...params) => fn('Event: on', ...params),
    timeout: (...params) => fn('Event: timeout', ...params),
    reset: (...params) => fn('Event: reset', ...params),
  }
}

function log(label, ...params) {
  prettyLog('📈 ' + label, { background: '#52bd95', color: '#fff' }, ...params)
}
