import Vue from 'vue'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import './setup/focus-visible'
import './setup/gfl'
import './setup/meta-info'
import './setup/cookies'
import './setup/segment'

import './assets/styles/fonts.scss'
import './assets/styles/index.scss'

import { prettyLog } from '@/utils'

const envVarsToLog = [
  'VUE_APP_COOKIE_DOMAIN',
  'VUE_APP_SEGMENT_WRITE_KEY',
  'VUE_APP_SEGMENT_SNIPPET_VERSION',
  'VUE_APP_SEGMENT_DISABLED',
  'VUE_APP_SEGMENT_MOCK',
]

envVarsToLog.forEach((envKey) => {
  prettyLog(
    `🔑 ${envKey}`,
    { background: '#ededed', color: '#252525' },
    process.env[envKey]
  )
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
