let meSessionId = ''

export function _meSessionId() {
  if (!meSessionId) {
    meSessionId =
      new Date().getTime() + '.' + Math.random().toString(36).substring(5)
  }
  return meSessionId
}

export function _meHitTimestamp() {
  const now = new Date()
  const tzo = -now.getTimezoneOffset()
  const dif = tzo >= 0 ? '+' : '-'
  const pad = function (num) {
    const norm = Math.abs(Math.floor(num))
    return (norm < 10 ? '0' : '') + norm
  }
  return (
    now.getFullYear() +
    '-' +
    pad(now.getMonth() + 1) +
    '-' +
    pad(now.getDate()) +
    'T' +
    pad(now.getHours()) +
    ':' +
    pad(now.getMinutes()) +
    ':' +
    pad(now.getSeconds()) +
    '.' +
    pad(now.getMilliseconds()) +
    dif +
    pad(tzo / 60) +
    ':' +
    pad(tzo % 60)
  )
}
