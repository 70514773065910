<template>
  <div class="ta:center">
    <h1 class="ff:secondary fs:32-rem fw:bold mb:24-rem">
      {{ title }}
    </h1>
    <p class="mb:24-rem">
      We really appreciate you taking the time to share your experience with us.
    </p>
    <k-card class="d:inline-block mb:24-rem p:16-rem">
      <k-review-stars height="2rem" :score="5" />
    </k-card>
    <k-loader label="Redirecting to Google reviews" />
  </div>
</template>

<script>
export default {
  name: 'RedirectToGoogle',
  props: {
    name: String,
  },
  computed: {
    title() {
      return `Thanks for your feedback${this.name ? `, ${this.name}` : ''}!`
    },
  },
}
</script>

<style lang="scss" scoped></style>
