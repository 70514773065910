<template>
  <div class="cookie-popup">
    <div class="cookie-popup__wrapper">
      <k-icon-button
        :icon="{
          name: 'Times',
          description: `Close cookie popup`,
          size: '1.2rem',
        }"
        type="invisible"
        class="cookie-popup__close"
        @click="acceptAll"
      />
      <p class="fs:14-rem mr:32-rem">
        We collect information to optimize and improve the performance of this
        website for the benefit of our visitors.
      </p>
      <div class="d:flex ai:center">
        <k-button
          ref="acceptCookies"
          type="primary"
          size="small"
          class="mt:16-rem mr:16-rem"
          tag="button"
          @click="acceptAll"
          >Accept all
        </k-button>
        <k-link tag="a" to="https://kasa.com/cookies" class="mt:16-rem">
          Preferences
        </k-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    acceptAll() {
      /** Actually just hides the popup, the user automatically accepts all by visiting the site if not specified otherwise */
      this.$emit('accept-all')
    },
  },
}
</script>

<style scoped lang="scss">
.cookie-popup {
  position: fixed;
  left: convert(24);
  right: convert(24);
  bottom: convert(24);
  width: 100%;
  max-width: calc(100% - #{convert(48)});
  background-color: $neutral-white;
  border-radius: $border-radius-large;
  box-shadow: $elevation-2;
  z-index: 10000;

  @include break-on(min-md) {
    left: auto;
    max-width: convert(424);
  }

  &__wrapper {
    padding: convert(16);
    position: relative;
    font-size: convert(14);
  }

  &__close {
    position: absolute !important;
    top: convert(8);
    right: convert(8);
  }
}
</style>
