export const errors = {
  NOT_FOUND: {
    code: 404,
    name: 'NotFound',
    message: "We can't seem to find the page your looking for",
  },
  DEFAULT: {
    code: 500,
    name: 'Default',
    message: 'Something went wrong',
  },
}
